var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{on:{"click":_vm.newEvent}},[_vm._v("New event")]),_c('Fullcalendar',{attrs:{"plugins":_vm.calendarPlugins,"header":{
      left: 'title',
      center: 'dayGridMonth, timeGridWeek, timeGridDay, listWeek',
      right: 'prev today next'
    },"buttonText":{
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      list: 'List'
    },"weekends":true,"selectable":true,"editable":true,"events":_vm.events,"eventContent":_vm.renderEventContent},on:{"select":_vm.handleSelect,"eventClick":_vm.handleEventClick,"eventResize":_vm.updateEvent,"eventDrop":_vm.updateEvent}}),(_vm.showModal)?_c('modal',{attrs:{"isVisible":_vm.showModal},on:{"close":function($event){_vm.showModal = false},"add-event":_vm.addEvent}}):_vm._e(),(_vm.showEventModal)?_c('event-modal',{attrs:{"isVisible":_vm.showEventModal,"event":_vm.selectedEvent},on:{"close":function($event){_vm.showEventModal = false},"update-event":_vm.updateEvent}}):_vm._e(),(_vm.showCommentsModal)?_c('comments-modal',{on:{"close":function($event){_vm.showCommentsModal = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }