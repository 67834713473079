<template>
    <div>
      <div>{{ text }}</div>
      <button @click="close">Close</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      text: String
    },
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }
  </script>
  
  <style>
  /* Add any additional styles here */
  </style>
  