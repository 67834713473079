<template>
  <div v-if="isVisible" class="modal" @click="closeModal">
    <div class="modal-content" @click.stop>
      <h2>일정등록</h2>
      <form @submit.prevent="submitForm">
        <label for="title">제목:</label>
        <input v-model="form.title" type="text" id="title" />
        
        <label for="start">시작일시:</label>
        <input v-model="form.start" type="datetime-local" id="start" />
        
        <label for="end">종료일시:</label>
        <input v-model="form.end" type="datetime-local" id="end" />
        
        <label for="content">내용:</label>
        <textarea v-model="form.content" id="content"></textarea>
        
        <label for="attendees">참석자:</label>
        <div class="attendees-section">
          <input v-model="form.attendees" type="text" id="attendees" placeholder="참석자 입력" />
          <button type="button" @click="openAttendeesModal">선택</button>
        </div>
        
        <label for="sms">
          SMS발송여부:
          <input type="checkbox" id="sms" v-model="form.sms" @change="toggleSmsFields" style="margin-left: 10px;" />
        </label>

        <div v-if="form.sms">
          <label for="template">발송문자 템플릿:</label>
          <select v-model="form.template" id="template">
            <option value="template1">템플릿 1</option>
            <option value="template2">템플릿 2</option>
            <option value="template3">템플릿 3</option>
          </select>

          <label for="send-days">예약발송일:</label>
          <select v-model="form.sendDays" id="send-days">
            <option v-for="n in 31" :key="n" :value="n">{{ n }}일 전</option>
          </select>
          <label for="send-time">예약발송시간:</label>
          <div class="send-time">
            <select v-model="form.sendHours" id="send-hours">
              <option v-for="hh in 23" :key="hh" :value="hh">
                <span>{{ hh }} 시</span>
              </option>
            </select>
            :
            <select v-model="form.sendMinutes" id="send-minutes">
              <option v-for="mm in 59" :key="mm" :value="mm">
                {{ mm }}분
              </option>
            </select>
          </div>
        </div>
        
        <button type="submit">저장</button>
        <button type="button" @click="closeModal">닫기</button>
      </form>
    </div>
  </div>
</template>


<script>
export default {
  props: ['isVisible', 'event'],
  data() {
    return {
      form: {
        title: '',
        start: '',
        end: '',
        content: '',
        sms: false,
        template: '',
        sendDays: '',
        sendHours: '10',
        sendMinutes: '00',
        attendees: ''
      }
    }
  },
  watch: {
    isVisible(val) {
      if (val && this.event) {
        this.form = {
          title: this.event.title || '',
          start: this.event.startStr || '',
          end: this.event.endStr || '',
          content: this.event.extendedProps.content || '',
          sms: this.event.extendedProps.sms || false,
          template: this.event.extendedProps.template || '',
          sendDays: this.event.extendedProps.sendDays || '',
          sendHours: this.event.extendedProps.sendHours || '00',
          sendMinutes: this.event.extendedProps.sendMinutes || '00',
          attendees: this.event.extendedProps.attendees || ''
        };
      }
    }
  },
  methods: {
    toggleSmsFields() {
      if (!this.form.sms) {
        this.form.template = '';
        this.form.sendDays = '';
        this.form.sendHours = '00';
        this.form.sendMinutes = '00';
      }
    },
    openAttendeesModal() {
      this.$emit('open-attendees'); // Open the attendees modal without closing the current one
    },
    submitForm() {
      console.log('Form submitted:', this.form);
      this.$emit('add-event', this.form); // 부모 컴포넌트로 이벤트 전달
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>


<style scoped>
.send-time {
  display: flex;
  align-items: center;
}

.send-time select {
  margin-right: 5px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

input[type="text"],
input[type="datetime-local"],
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

input[type="checkbox"] {
  margin-left: 10px;
  transform: scale(1.2);
}

button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="button"] {
  background-color: #6c757d;
}

.attendees-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.attendees-section input {
  flex: 1;
  margin-right: 10px;
}
</style>
