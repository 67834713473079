<template>
  <div>
    <button @click="newEvent">New event</button>
    <Fullcalendar 
      :plugins="calendarPlugins" 
      :header="{
        left: 'title',
        center: 'dayGridMonth, timeGridWeek, timeGridDay, listWeek',
        right: 'prev today next'
      }"
      :buttonText="{
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List'
      }"
      :weekends="true"
      :selectable="true"
      :editable="true"
      :events="events"  
      @select="handleSelect"
      @eventClick="handleEventClick"
      @eventResize="updateEvent"
      @eventDrop="updateEvent"
      :eventContent="renderEventContent"
    />

    <modal 
      v-if="showModal" 
      :isVisible="showModal" 
      @close="showModal = false" 
      @add-event="addEvent"
    />
    <event-modal 
      v-if="showEventModal" 
      :isVisible="showEventModal" 
      :event="selectedEvent" 
      @close="showEventModal = false" 
      @update-event="updateEvent"
    />
    <comments-modal v-if="showCommentsModal" @close="showCommentsModal = false" />
  </div>
</template>

<script>
import Fullcalendar from '@fullcalendar/vue'
import DayGridPlugin from '@fullcalendar/daygrid'
import TimeGridPlugin from '@fullcalendar/timegrid'
import InteractionPlugin from '@fullcalendar/interaction'
import ListPlugin from '@fullcalendar/list'
import { mapGetters } from 'vuex'
import Modal from './Modal'
import EventModal from './EventModal'
import CommentsModal from './CommentsModal'

export default {
  data: () => ({
    calendarPlugins: [
      DayGridPlugin,
      TimeGridPlugin,
      InteractionPlugin,
      ListPlugin
    ],
    showModal: false,
    showEventModal: false,
    showCommentsModal: false,
    selectedEvent: null,
    selectedDate: null,
    events: [] // 이벤트 배열
  }),
  components: { 
    Fullcalendar, 
    Modal, 
    EventModal, 
    CommentsModal 
  },
  computed: {
    ...mapGetters(["events"])  
  },
  methods: {
    newEvent() {
      this.showModal = true;
    },
    renderEventContent(arg) {
      const commentIcon = document.createElement('span');
      commentIcon.className = 'fa fa-comments';
      commentIcon.addEventListener('click', event => {
        event.stopPropagation();
        this.showCommentsModal = true;
      });
      arg.el.appendChild(commentIcon);
      return { domNodes: [commentIcon] };
    },
    updateEvent(eventData) {
      const eventIndex = this.events.findIndex(event => event.id === eventData.id);
      if (eventIndex !== -1) {
        this.events.splice(eventIndex, 1, {
          ...this.events[eventIndex],
          ...eventData
        });
      } else {
        this.events.push(eventData);
      }
    },
    handleSelect(arg) {
      this.selectedDate = {
        start: arg.start,
        end: arg.end
      };
      this.showModal = true;
    },
    handleEventClick(arg) {
      this.selectedEvent = {
        id: arg.event.id,
        title: arg.event.title,
        startStr: arg.event.startStr,
        endStr: arg.event.endStr,
        extendedProps: arg.event.extendedProps
      };
      this.showEventModal = true;
    },
    addEvent(eventData) {
      const newEvent = {
        title: eventData.title,
        start: eventData.start,
        end: eventData.end,
        content: eventData.content,
        id: Date.now() // 임시 ID 생성
      };
      this.events.push(newEvent);
    }
  }
}
</script>
